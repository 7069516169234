"use client";
import { User } from "@/api/users/types";
import { Menu } from "@headlessui/react";
import { UserImage } from "../../../Basic/UserImage";
import { getUsersName } from "@/api/users/helpers";
import DropdownItems from "./DropdownItems";
import { twMerge } from "tailwind-merge";

interface Props {
  user: User;
  small?: boolean;
}

const UserDropdown = ({ user, small }: Props) => {
  return (
    <div className="lg:relative flex items-center">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button>
              <div
                className={twMerge(
                  "flex items-center justify-center lg:h-12 lg:w-auto bg-endemicGray-50 lg:border-4 rounded-3xl border-white shadow-md",
                  small && "h-9",
                )}
              >
                <UserImage
                  image={{ src: user.imageUrl }}
                  imagePlaceholder={user.imagePlaceholder}
                  name={getUsersName(user)}
                  className={twMerge(
                    "h-11 w-11 lg:w-10 lg:h-10 overflow-hidden rounded-full",
                    small && "w-9 h-9",
                  )}
                  imgClassName={twMerge(
                    "min-h-[40px] min-w-[40px]",
                    small && "min-h-[36px] min-w-[36px]",
                  )}
                />
                <div className="bg-gray pl-2 pr-4 text-left hidden lg:block">
                  <div className="text-red font-bold text-[13px] leading-[1.2]">
                    Level {user.level}
                  </div>
                  <div className="text-[11px] leading-[1.2] font-light text-endemicGray">
                    {user.points} XP
                  </div>
                </div>
              </div>
            </Menu.Button>
            <DropdownItems isOpen={open} user={user} />
          </>
        )}
      </Menu>
    </div>
  );
};

export default UserDropdown;

"use client";
import React, { Fragment, ReactElement } from "react";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import { twMerge } from "tailwind-merge";
import ChevronIcon from "@/assets/icons/chevron.svg";
import { NavigationActiveItemIndicator } from "../NavigationActiveItemIndicator";
import { Popover, Transition } from "@headlessui/react";
import { DropdownMenu } from "../../../Basic/DropdownMenu";

type SubMenuItem = Omit<MenuItem, "subMenu">;

export interface MenuItem {
  href: string;
  name: string | ReactElement;
  title?: string;
  description?: string;
  image?: ReactElement;
  isBase?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  subMenu?: SubMenuItem[];
  hideActiveIndicator?: boolean;
  iconNameOnMobile?: string;
  className?: string;
  target?: string;
}

export interface Props {
  menuItem: MenuItem;
  preserveSearchQuery: boolean;
  shouldHideActiveIndicatorOnScroll?: boolean;
  className?: string;
}

function NavigationItem({
  menuItem,
  preserveSearchQuery = false,
  shouldHideActiveIndicatorOnScroll,
  className,
}: Props) {
  const pathname = usePathname();
  const query = useSearchParams();
  let searchQuery = query.toString();

  if (menuItem.href !== "/search/artwork") {
    const params = new URLSearchParams(searchQuery);
    params.delete("marketplace");
    searchQuery = params.toString();
  }

  const href = preserveSearchQuery
    ? `${menuItem.href}?${searchQuery}`
    : menuItem.href;

  const isActivePath = (menuItem: MenuItem | SubMenuItem): boolean => {
    if ("subMenu" in menuItem) {
      return menuItem.subMenu!.some((subMenuItem: SubMenuItem) =>
        isActivePath(subMenuItem),
      );
    } else {
      return menuItem.isBase
        ? menuItem.href === pathname
        : pathname.startsWith(menuItem.href);
    }
  };

  return (
    <>
      {!!menuItem.subMenu?.length ? (
        <Popover className="relative">
          {({ open }) => {
            return (
              <div>
                <Popover.Button className="popover-button relative group inline-flex items-center outline-none text-lg leading-6 px-3 xl:px-3  ">
                  <div className="flex items-center gap-x-2">
                    {isActivePath(menuItem) ? (
                      <div className="text-red endemic:text-yellow font-bold">
                        {menuItem.name}
                      </div>
                    ) : (
                      <div className="font-normal">{menuItem.name}</div>
                    )}
                  </div>
                  <ChevronIcon
                    className={twMerge(
                      open ? "rotate-180" : "",
                      "absolute fill-primary h-[6px] -right-2",
                      isActivePath(menuItem)
                        ? "fill-current text-red endemic:text-yellow"
                        : "",
                    )}
                  />
                  {isActivePath(menuItem) && (
                    <NavigationActiveItemIndicator
                      hideOnScroll={shouldHideActiveIndicatorOnScroll}
                      className={className}
                    />
                  )}
                </Popover.Button>
                <Popover.Overlay className="fixed inset-0 bg-black opacity-0" />

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute left-1/2 z-20 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                    {({ close }) => (
                      <DropdownMenu menuItem={menuItem} close={close} />
                    )}
                  </Popover.Panel>
                </Transition>
              </div>
            );
          }}
        </Popover>
      ) : (
        <Link
          href={href}
          onClick={menuItem.onClick}
          className={twMerge(
            "text-lg leading-6 flex items-center text-center relative ml-2 whitespace-nowrap",
            menuItem.className,
            isActivePath(menuItem)
              ? "text-red endemic:text-yellow font-bold"
              : "",
          )}
          target={menuItem.target}
        >
          {menuItem.name}

          {isActivePath(menuItem) && !menuItem.hideActiveIndicator && (
            <NavigationActiveItemIndicator
              hideOnScroll={shouldHideActiveIndicatorOnScroll}
              className={className}
            />
          )}
        </Link>
      )}
    </>
  );
}

export default NavigationItem;
